<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">References</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x pt-5">
          <div class="cell small-12 medium-6">
            <ol class="force-wrap">
              <li>Population by ICB, NHS England. https://www.england.nhs.uk/publication/supporting-spreadsheets-for-allocations-2022-23/ Last accessed March 2023</li>
              <li>Population by Health Board, Scotland. https://www.nrscotland.gov.uk/statistics-and-data/statistics/statistics-by-theme/population/population-projections/sub-national-population-projections/2018-based/summary-datasets#NHS Last accessed March 2023</li>
              <li>Population by Health Board, Wales. https://statswales.gov.wales/Catalogue/Population-and-Migration/Population/Estimates/Local-Health-Boards/populationestimates-by-welshhealthboard-year Last accessed March 2023</li>
              <li>Population, Health & Social Care Trusts, Northern Ireland. Northern Ireland Statistics & Research Agency (NISRA)</li>
              <li>Byooviz SmPC</li>
              <li>Lucentis SmPC</li>
              <li>BNF. https://bnf.nice.org.uk/drugs/ranibizumab/medicinal-forms/ Last accessed March 2023</li>
              <li>Office of National Statistics. https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates Last accessed March 2023</li>
              <li>The Royal College of Ophthalmologists. Commissioning Guidance Age Related Macular Degeneration Services. https://www.rcophth.ac.uk/wp-content/uploads/2021/08/AMD-Commissioning-Guidance-Full-June-2021.pdf Last accessed March 2023</li>
              <li>Jones CD et al . Diabetes Care 35:592–596, 2012</li>
            </ol>
          </div>
          <div class="cell small-12 medium-6">
            <ol class="force-wrap" start="11">
              <li>NICE Health Technology Appraisal Faricimab for treating diabetic macular oedema Draft scope. https://urldefense.com/v3/__https:/www.nice.org.uk/guidance/ta799/documents/draft-scope-post-referral__;!!LqrO4s96!iHDgNYibbmuuB8UKNxN6ZPUY3SgnGZblG6c8EIZg2S7agSaNZIFV0lxcXDow8-HPDnd9YG86g_6PadOHqsl8ZsCaI0hfhim1$ Last accessed March 2023</li>
              <li>World Population Prospects 2022, United Nations. https://www.un.org/development/desa/pd/sites/www.un.org.development.desa.pd/files/wpp2022_summary_of_results.pdf Last accessed March 2023</li>
              <li>The American Academy of Ophthalmology. https://www.aao.org/education/topic-detail/diabetic-retinopathy-europe Last accessed March 2023</li>
              <li>The Royal College of Ophthalmologists. Retinal Vein Occlusion (RVO) Clinical Guidelines. January 2022. https://www.rcophth.ac.uk/resources-listing/retinal-vein-occlusion-rvo-guidelines/ Last accessed March 2023</li>
              <li>NICE Single Technology Appraisal, Aflibercept for treating myopic choroidal neovascularisation, Final scope https://www.nice.org.uk/guidance/ta486/documents/final-scope Last accessed March 2023</li>
            </ol>
            <p class="text-right pt-5">
              <button @click="goBack" class="button">Back</button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'References',
  components: {
    Header,
  },
  computed: {
    hash() {
      return this.$store.state.hash;
    },
  },
  methods: {
    goBack() {
      this.$router.go('-1');
    },
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
  },
  mounted() {
    this.checkHashExists();
  },
};
</script>

<style lang="scss">
  .force-wrap li {
    word-wrap: break-word;
  }
</style>
